import React from "react";
import ReactDOM from "react-dom";
import Review from "./Review";

const renderWidget = (id, widget) => {
  if (document.getElementById(`${id}`)) {
    ReactDOM.render(
      <React.StrictMode>{widget}</React.StrictMode>,
      document.getElementById(`${id}`)
    );
  }
};

renderWidget(
  "reviewWidget",
  <Review
    dataValue={
      document.getElementById("reviewWidget")
        ? JSON.parse(
            JSON.stringify(document?.getElementById("reviewWidget")?.dataset)
          )
        : ""
    }
  />
);
