import React from "react";
import styled from "styled-components";
const getStars = (number) => {
  const starsArray = [1, 2, 3, 4, 5];

  return starsArray.slice(0, number).map((item) => {
    return (
      <img
        src="https://assets.website-files.com/60d0250c3ed9bd24020018d8/60d17bb34dcffb63a66a690b_star.svg"
        loading="lazy"
        alt=""
        className="reviewstar"
        key={item}
      />
    );
  });
};

const ReviewCard = ({ data }) => {
  const reviewerName = "Sarah";
  const gardenName = "Sarvodya Farms";
  return (
    <ReviewCardWrapper>
      <h4 className="name">{data.review.name}</h4>
      <strong className="reviewerName">
        {`By ${reviewerName}`} <span> {`on ${data.review.date}`}</span>
      </strong>
      <p className="date">{gardenName}</p>
      <p className="stars">{getStars(data.rating)}</p>
      <p className="text truncate">{data.review.message}</p>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
const ReviewCardWrapper = styled.div`
  height: 100%;
  margin: 0 10px;
  border-radius: 6px;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #00848926;

  &:hover {
    border-color: transparent;
    box-shadow: 10px 9px 20px 0 #0000000d;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
  }

  img {
    max-width: 100%;
    display: block;
  }

  .truncate {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  & > .name {
    font-size: 16px;
    margin-bottom: 4px;
  }
  & > .reviewerName {
    font-size: 14px;
    color: #008489;
    margin-bottom: 4px;
    display: block;

    span {
      font-size: 12.6px;
      color: #777;
      font-weight: 400;
    }
  }
  & > .date {
  }
  & > .text {
    font-size: 12.6px;
    line-height: 1.4;
  }
  & > .stars {
    display: flex;
    padding: 12px 0;
  }
`;
