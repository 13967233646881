export const QUERY_EVENTS_DATA = (start, end, slug, type) => {
  return JSON.stringify({
    query: `query GetProducts($recordOffset: Int!, $pageSize: Int!, $name: String, $type: String) {
  product(offset: $recordOffset, limit: $pageSize, order_by: {created_at: asc}, where: {active: {_eq: true}, type: {_eq: $type}, listing: {active: {_eq: true}, name: {_ilike: $name}}}) {
    id
    campaign
    min_price
    max_price
    name
    type
    category
    images
    product_details(order_by: {id: asc}, limit: 1) {
      id
    }
    listing {
      name
      slug
      position
      address
      host {
        name
        photo
        instagram
      }
    }
  }
}`,
    variables: {
      recordOffset: start,
      pageSize: end,
      name: slug || null,
      type: type || null,
    },
  });
};

export const QUERY_REVIEW_DATA = (start, end, slug, rating) => {
  return JSON.stringify({
    query: `query MyQuery($recordOffset: Int!, $pageSize: Int!, $name: String, $rating: Int) {
 review(
 offset: $recordOffset,
 limit: $pageSize,
 where: {deleted: {_eq: false},rating:{_eq:$rating},listing:{name:{_ilike:$name}}}
 order_by: {id: asc}) {
 id
 rating
 review
 }
}`,
    variables: {
      recordOffset: start,
      pageSize: end,
      name: slug || null,
      rating: rating || null,
    },
  });
};
export const QUERY_GARDEN_DATA = (start, end, name) => {
  return JSON.stringify({
    query: `
    query GetGardens($recordOffset: Int!, $pageSize: Int!, $name: String) {
    listing(offset: $recordOffset, limit: $pageSize, order_by: {id: asc}, where: {active: {_eq: true}, name:{_ilike: $name}}) {
    id
    name
    acuity_id
    slug
    position
    address 
    images
    amenities
    campaigns
    reviews_aggregate {
      aggregate {
        avg {
          rating
        }
      }
    }
   products(where: {active: {_eq: true}}) {
      id
      type
    }
  }
}
    `,
    variables: {
      recordOffset: start,
      pageSize: end,
      name: name || null,
    },
  });
};

export const QUERY_SERVICE_DATA = (id, eventId) => {
  return JSON.stringify({
    query: `query GetEvents($_id: Int, $_eventId:Int) {
      service_allocations_view(where: {listing_id: {_eq: $_id},id: {_eq: $_eventId}}) {
        id
        name
        created_at
        images
        price_list
        start_date
        end_date
        type
        hg_service_type
        listing_name
        is_public
        position
        price
        booking_limit
      }
    }`,
    variables: {
      _id: id,
      _eventId: eventId,
    },
  });
};

export const QUERY_OFFERINGS_DATA = (id, eventId) => {
  return JSON.stringify({
    query: `query GetOfferings($_id: Int,$_eventId:Int)  {
      service(where: {listing: {id: {_eq: $_id}, active: {_eq: true}}, is_public: {_eq: true}, hg_service_type: {_eq: 1}, id: {_eq: $_eventId}, deleted: {_eq: false}}, order_by: {created_at: desc})  {
        created_at
        hg_service_type
        id
        images
        name
        price
        price_list
        type
        is_public
        listing {
          address
          position
          name

        }
      }
    }`,
    variables: {
      _id: id,
      _eventId: eventId,
    },
  });
};

export const QUERY_GARDENS_DATA = () => {
  return JSON.stringify({
    query: `query GetGardens {
      listing(where: {active: {_eq: true}}) {
        id
        name
        address
        amenities
        slug
        services {
          type
        }
        images
        position
      }
    }`,
  });
};
