import React from "react";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
// import "react-multi-carousel/lib/styles.css";
import ReviewCard from "../../components/Cards/ReviewCard";
import Loader from "../../components/Cards/Loader";
import { QUERY_REVIEW_DATA } from "../../common/api/queries";
import { startFetch } from "../../common/api/hg-hasura";
let paginate = {
  start: 0,
  end: 5,
  increment: 5,
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Review = ({ dataValue }) => {
  const [loading, setLoading] = React.useState(true);
  const [cardData, setCardData] = React.useState([]);
  const reviewCarousel = React.useRef(null);

  const handleFetchData = async (start, end, slug, rating) => {
    paginate.start = 0;
    setLoading(true);
    const response = await startFetch(
      QUERY_REVIEW_DATA(paginate.start, end, slug, rating)
    );
    const data = await response.json();
    setLoading(false);
    setCardData(data.data.review);
  };

  const handlePagination = async (start, end, slug, rating) => {
    const response = await startFetch(
      QUERY_REVIEW_DATA(start, end, slug, rating)
    );
    const data = await response.json();
    setLoading(false);
    setCardData((cardData) => [...cardData, ...data.data.review]);
  };

  React.useEffect(() => {
    handleFetchData(
      paginate.start,
      paginate.end,
      dataValue?.slug,
      dataValue?.rating
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue?.slug, dataValue?.rating]);

  return (
    <div
      style={{
        overflow: "hidden",
        minHeight: "300px",
      }}
    >
      <ReviewSliderWrapper>
        <ReviewHeading>
          <h2>Customer Reviews</h2>
        </ReviewHeading>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {cardData.length ? (
              <React.Fragment>
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  partialVisible={true}
                  ref={reviewCarousel}
                  renderArrowsWhenDisabled={true}
                  beforeChange={(nextSlide) => {
                    const slidePerSlide =
                      reviewCarousel.current.state.slidesToShow;
                    if (nextSlide === cardData.length - slidePerSlide) {
                      paginate.start = paginate.start + paginate.increment;
                      handlePagination(
                        paginate.start,
                        paginate.end,
                        dataValue?.slug,
                        dataValue?.rating
                      );
                    }
                  }}
                >
                  {cardData.map((item) => {
                    return <ReviewCard data={item} key={item.id} />;
                  })}
                </Carousel>
              </React.Fragment>
            ) : (
              <NoDataFound>No reviews found</NoDataFound>
            )}
          </React.Fragment>
        )}
      </ReviewSliderWrapper>
    </div>
  );
};

export default Review;

const ReviewHeading = styled.div`
  h2 {
    font-size: 25.2px;
    margin-top: 0;
    margin-bottom: 12px;
    max-width: calc(100% - 80px);
  }
`;

const ReviewSliderWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 0;

  .react-multi-carousel-list {
    padding: 16px 0;
    margin-left: -10px;
    overflow: visible !important;
    position: relative;

    .react-multi-carousel-item {
    }

    .react-multiple-carousel__arrow {
      position: absolute;
      top: -40px;
      right: 0;
      min-width: unset;
      min-height: unset;
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-color: hsl(182, 100%, 27%);

      &:hover {
        background-color: hsl(182, 100%, 35%);
      }
    }
    .react-multiple-carousel__arrow::before {
      font-size: 14px;
    }

    .react-multiple-carousel__arrow--left {
      right: 40px;
      left: unset;
    }
    .react-multiple-carousel__arrow--right {
    }

    .react-multiple-carousel__arrow--left[disabled] {
      cursor: not-allowed;
      opacity: 0.7;
      background: none;
      border: 2px solid #008489;
      color: #008489;
      &::before {
        color: #008489;
      }
    }
    .react-multiple-carousel__arrow--right[disabled] {
      opacity: 0.5;
    }
  }
`;

const NoDataFound = styled.div`
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
