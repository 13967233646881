const { REACT_APP_HASURA_ENDPOINT } = process.env;

export const startFetch = async (query) => {
  const response = await fetch(`${REACT_APP_HASURA_ENDPOINT}`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: query,
  });
  return response;
};
